<!-- 通话弹窗组件  -->
<template>
  <transition name="call-dailog">
    <div v-if="visible" class="call-dailog">
      <div class="call-dailog-content">
        <div id="remote-video" class="large-video"></div>
        <div id="local-video" class="samll-video"></div>
        <div class="hang-up">
          <div @click="handleHangUp(true)" class="hang-up-btn">
            <i class="el-icon-close"></i>
          </div>
          <span class="hang-up-text">挂断</span>
        </div>
      </div>
      <div v-if="detail !== null" class="detail">
        <div class="detail_row1">
          <img :src="detail.photo" class="detail_avatar">
          <div style="margin-left: 10px;">
            <div class="detail_name">{{ detail.realname }}</div>
            <div class="flex" style="font-size: 0.9375vw;margin-top: 0.9259259259259258vh;">
              <span v-if="detail.sex !== 0" style="margin-right: 20px;">未知</span>
              <span v-else>{{ sexDict[detail.sex].label }}</span>
              <span>{{ detail.age }}岁</span>
            </div>
          </div>
        </div>
        <div class="detail_line"></div>
        <div class="detail_row2">
          <ul>
            <li>生日：{{ detail.birthday || '' }}</li>
            <li>联系方式：{{ detail.mobile || '' }}</li>
            <li>
              <div class="ellipsis" style="width: 15.625vw;">疾病史：{{ detail.disease || '' }}</div>
            </li>
            <li>
              <div class="ellipsis" style="width: 15.625vw;">长期用药：{{ detail.medication || '' }}</div>
            </li>
          </ul>
        </div>
        <div class="detail_line"></div>
        <div class="detail_row3">
          <div>
            <div style="margin-left: 40px;">近期健康数据：</div>
            <div class="flex" style="font-size: 0.75vw;">
              <ul>
                <li>
                  心率:
                  <span v-if="detail.device1 === null"></span>
                  <span v-else>{{ detail.device1.info === null ? '' : detail.device1.info.heart }}</span>
                </li>
                <li>
                  脉率:{{ detail.health_patrol_monitor === null ? '' : detail.health_patrol_monitor.pr }}
                </li>
                <li>
                  收缩压/舒张压:
                  {{ detail.health_patrol_monitor === null ? '' : detail.health_patrol_monitor.sp }} /
                  {{ detail.health_patrol_monitor === null ? '' : detail.health_patrol_monitor.dp }}
                </li>
              </ul>
              <ul>
                <li>呼吸:{{ detail.health_patrol_monitor === null ? '' : detail.health_patrol_monitor.breath }}</li>
                <li>血糖:{{ detail.health_patrol_monitor === null ? '' : detail.health_patrol_monitor.blood }}</li>
                <li>血氧:{{ detail.health_patrol_monitor === null ? '' : detail.health_patrol_monitor.spo2h }}</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="detail_line"></div>
        <div class="detail_row4">
          <div style="padding-left: 40px;">
            <div>紧急联系人：{{ detail.emergency || '' }}</div>
            <div style="margin-top: 0.2604166666666667vw;">联系方式：{{ detail.emergency_tel || '' }}</div>
            <div style="margin-top: 1.0416666666666667vw;">紧急联系人：{{ detail.emergency2 || '' }}</div>
            <div style="margin-top: 0.2604166666666667vw;">联系方式：{{ detail.emergency2_tel || '' }}</div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { ZegoExpressEngine } from 'zego-express-engine-webrtc'
import { getZEGOTokenAPI, getVideoCallDetailAPI } from '@/api/video-call.js'
// 消息常量
import { msgType } from '@/utils/constant.js'
import { sexDict } from '@/utils/dict.js'

const ZEGO_APPID = 1000730574
const ZEGO_SERVER_URL = 'wss://webliveroom1000730574-api.imzego.com/ws'
// 即构实例
let zg = null
let localStream = null
// 房间id
let roomId = ''
// 自己的后台系统 id 作为推流 id
let selfId = sessionStorage.getItem('systemUserId')

export default {
  name: 'VideoCallDialog',
  data() {
    return {
      videoChannel: process.env.VUE_APP_VIDEO_CHANENL,

      sexDict,

      visible: false,
      isSmall: true, // 用于大小视频窗口切换,
      hangupTimer: null,
      detail: null,
    }
  },
  mounted() {
    this.$mqtt.on('connect', () => console.log('连接成功'))
    this.$mqtt.on('message', (topic, msgRes) => {
      const msg = JSON.parse(msgRes.toString())

      if (msg.senderId == selfId) return
      if (msg.type === msgType.HANG_UP) this.handleHangUp(false)
    })
  },
  methods: {
    open(userId) {
      this.visible = true
      roomId = userId.toString()
      // 等弹窗显示再进行呼叫
      this.$nextTick(() => this.call())
      getVideoCallDetailAPI({ member_id: userId }).then(res => {
        console.log('detail', res);
        this.detail = res
      })
    },
    call() {
      // 订阅消息
      this.$mqtt.subscribe(`${this.videoChannel}-${selfId}`, () => console.log('subscribe'))
      // 创建引擎
      zg = new ZegoExpressEngine(ZEGO_APPID, ZEGO_SERVER_URL)
      // 监听房间内其他用户音视频流变化的通知
      this.listenRoomStreamUpdate()
      // 登录房间
      this.loginRoom()
    },
    // 登录房间
    async loginRoom() {
      const token = await this.getZEGOToken()

      zg.loginRoom(roomId, token, {
        userID: selfId,
      }).then(async res => {
        if (res == true) {

          localStream = await zg.createStream();
          let localView = zg.createLocalStreamView(localStream)
          localView.play('local-video')
          zg.startPublishingStream(selfId, localStream)

          this.send({
            type: msgType.CALL,
          })
        } else {
          this.$message({
            type: 'error',
            duration: 4000,
            message: '发起通话错误，请重试'
          })
          this.handleHangUp(true)
        }
      })
    },
    // 监听房间内其他用户音视频流变化的通知
    listenRoomStreamUpdate() {
      zg.on('roomStreamUpdate', (roomID, updateType, streamList) => {
        const remoteStreamId = streamList[0].streamID

        switch (updateType) {
          case 'ADD':
            zg.startPlayingStream(remoteStreamId).then(remoteStream => {
              const remoteView = zg.createRemoteStreamView(remoteStream)
              remoteView.play("remote-video")
            })
            break
          case 'DELETE':
            zg.stopPlayingStream(remoteStreamId)
            this.handleHangUp(false)
            break
        }
      })
    },
    // 挂断 @param {Boolean} isVoluntary
    handleHangUp(isVoluntary) {
      if (this.hangupTimer !== null) {
        clearTimeout(this.hangupTimer)
      }
      this.hangupTimer = setTimeout(() => {
        // 停止推流、销毁流
        zg.stopPublishingStream(selfId)
        zg.destroyStream(localStream)
        localStream = null
        zg.logoutRoom(roomId)
        // 如果为真说明是主动挂断
        if (!isVoluntary) {
          this.$alert('对方已挂断', '通知')
        }
        this.close()
      }, 300)
    },
    close() {
      this.$emit('closeCallDialog')
      this.visible = false
    },
    // 发送消息，消息格式: { type: '', roomId: '', senderId: '', data: 消息体, isAdmin: 1 }
    send(data) {
      const msgBody = Object.assign(data, {
        isAdmin: 1,
        senderId: selfId,
        roomId,
      })
      this.$mqtt.publish(`${this.videoChannel}-${roomId}`, JSON.stringify(msgBody))
    },
    async getZEGOToken() {
      const token = await getZEGOTokenAPI({
        userId: selfId
      }).then(res => res)
      return token
    }
  }
}
</script>

<style scoped lang="less">
// 设计稿 1920 , 1vw = 1920 / 100 = 19.2
@large-video-width: 19.791666666666668vw; // 380 / 19.2
@large-video-height: 33.85416666666667vw; // 650/ 19.2
// 整体缩小四倍
@small-video-wdith: 4.947916666666667vw; // 380 / 4 / 19.2, 
@small-video-height: 8.463541666666668vw; // 650 / 4 / 19.2

/deep/ #local-video,
/deep/ #remote-video {
  video {
    object-fit: cover !important;
  }
}

ul li {
  margin: 0.2604166666666667vw 0;
}

.call-dailog {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.5);
}

.call-dailog-content {
  position: relative;
  border-radius: 4px;
  width: @large-video-width;
  height: @large-video-height;
  overflow: hidden;
}

.call-dailog-enter-active,
.call-dailog-leave-active {
  transition: all .3s;
}

.call-dailog-enter,
.call-dailog-leave-to {
  opacity: 0;
}

.large-video {
  object-fit: cover;
  width: @large-video-width;
  height: @large-video-height;
  background: #000;
}

.samll-video {
  border: 1px solid #FFF;
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 1000;
  object-fit: cover;
  width: @small-video-wdith;
  height: @small-video-height;
}

.hang-up {
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hang-up-btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  color: #FFF;
  background: #FA5151;
}

.hang-up-text {
  margin-top: 5px;
  font-size: 14px;
  color: #FFF;
}

.detail {
  width: @large-video-width;
  height: @large-video-height;
  color: #FFF;
  overflow: hidden;
  background: #4f4f4f;

  .detail_row1 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5.78125vw;
  }

  .detail_avatar {
    width: 3.75vw;
    height: 3.75vw;
    border-radius: 4px;
  }

  .detail_name {
    font-size: 1.5625vw;
  }

  .detail_line {
    border-bottom: 1px solid #818181;
    margin: 0 0.625vw;
  }

  .detail_row2 {
    display: flex;
    align-items: center;
    height: 8.958333333333334vw;
    font-size: 0.9375vw;
  }

  .detail_row3 {
    display: flex;
    align-items: center;
    height: 8.28125vw;
    font-size: 0.9375vw;
  }

  .detail_row4 {
    display: flex;
    align-items: center;
    height: 10.3vw;
    font-size: 0.9375vw;
  }
}
</style>