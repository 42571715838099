// 视屏通话
import request from '@/utils/util.js'

// 获取即构token
export function getZEGOTokenAPI(data) {
	return request.get('/call/token', data)
}

// 获取通话详情
export function getVideoCallDetailAPI(data) {
  return request.get('/call/detail', data)
}